import { AppError, getMysqlDateIgnoreTZ, Language, Lexeme, LexemePairWithLang } from 'czapp-shared'
import React, { ChangeEvent, useContext, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'
import { Api } from '../../util/Api'
import { Logger } from '../../util/Logger'
import { ActiveOverlay } from '../App';
import { CUSTOM_BUTTON_LABEL, CustomButton } from '../generic/CustomButton'
import { InputArea } from '../generic/InputArea'
import { InputLanguageButton } from '../generic/InputLanguageButton'
import { InputWithLanguageFlag } from '../generic/InputWithlanguageFlag'
import { AppContext } from '../RootComponent'

const CUSTOM_BUTTON_CLASS = 'icon material-symbols-outlined customButton'
const ADD_CONFIRMATION_TIMEOUT_MS = 2_000

const pulsingRecordButton = keyframes`
    0%, 100% { 
        background-color: red; 
    }
    50% { 
        background-color: #302f2d;
    }
`

const noPulse = keyframes``

interface SpeechButtonProps {
    $isRecording?: boolean
    $isSmallVersion?: boolean
}

const SpeechButton = styled.button<SpeechButtonProps>`
    border-radius: 50%;
    background-color: ${(props) =>
        props.$isSmallVersion ? 'rgba(0,0,0,0)' : '#3b3938'};
    color: #ffffff;
    font-size: ${(props) =>
        props.$isSmallVersion ? 'min(10vw, 10vh)' : 'min(25vw, 10vh)'};
    padding: ${(props) =>
        props.$isSmallVersion ? 'min(3vw, 1.5vh)' : 'min(6vw, 3vh)'};
    animation: ${(props) =>
            props.$isRecording ? pulsingRecordButton : noPulse}
        1s infinite;
    border: 1vw white solid;
`

const TranslateButton = styled.button`
    font-size: 6vw;
    padding: 4vw 8vw;
    font-weight: bold;
`

const CenteredContent = styled.div`
    text-align: center;
    height: 80%;
`

const InstructionText = styled.p`
    color: #cfc6b2;
    padding: 40px 25%;
    font-size: 8vw;
`

const RecognisedText = styled.div`
    color: white;
    font-size: 24px;
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const RecordingMessage = styled.p`
    color: white;
    font-size: 24px;
    padding: 20px;
`

enum CurrentView {
    ENTRY_POINT,
    RECORDING,
    TYPING,
    TRANSLATING,
    CONFIRMATION,
}

export interface AddNuProps {
    editing?: LexemePairWithLang | undefined
}

export const AddNu = (props: AddNuProps) => {
    const dictionary = useDictionary()
    const context = useContext(AppContext)
    const [translationText, setTranslationText] = React.useState('')
    const [inputLanguage, setInputLanguage] = React.useState<Language>(
        Language.ENGLISH
    )
    const [currentView, setCurrentView] = React.useState<CurrentView>(
        CurrentView.ENTRY_POINT
    )
    const [isTranslationLoading, setIsTranslationLoading] =
        React.useState(false)

    // Input text state needs to be accessible in callbacks, so we need to use a ref
    const [inputText, _setInputText] = React.useState('')
    const inputTextRef = useRef<string>(inputText)
    const setInputText = (text: string) => {
        inputTextRef.current = text
        _setInputText(text)
    }

    // Recording state needs to be accessible in callbacks, so we need to use a ref
    const [isRecording, _setIsRecording] = React.useState(false)
    const isRecordingRef = useRef<boolean>(isRecording)
    const setIsRecording = (isRecording: boolean) => {
        isRecordingRef.current = isRecording
        _setIsRecording(isRecording)
    }

    const recognition = useRef<SpeechRecognition | null>(null)

    const onSpeechRecognitionServiceResult = (
        event: SpeechRecognitionEvent
    ) => {
        let output = ''
        for (let i = 0; i < event.results.length; i++) {
            output += event.results[i][0].transcript
        }
        if (isRecordingRef.current) setInputText(output)
    }

    const onSpeechRecognitionServiceStart = () => {
        console.log('Speech recognition service started');
        setIsRecording(true)
    }

    const onSpeechRecognitionServiceEnd = () => {
        console.log('Speech recognition service ended');
        setIsRecording(false)
    }

    const onSpeechRecognitionServiceError = (event: SpeechRecognitionErrorEvent) => {
        console.log('Speech recognition service error', event)
        if(event.error === 'not-allowed') {
            alert(dictionary.MICROPHONE_ACCESS_DENIED)
            setCurrentView(CurrentView.ENTRY_POINT)
        }
    }

    useEffect(() => {
        // TODO - figure out why const doesn't work in this case... hoisting??
        // @ts-expect-error no var
        // eslint-disable-next-line no-var
        var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
        recognition.current = new SpeechRecognition()
        if (!recognition.current) return
        recognition.current.continuous = true
        recognition.current.interimResults = true
        recognition.current.lang = inputLanguage
        recognition.current.maxAlternatives = 1

        recognition.current?.addEventListener('result', onSpeechRecognitionServiceResult)
        recognition.current?.addEventListener('start', onSpeechRecognitionServiceStart)
        recognition.current?.addEventListener('end', onSpeechRecognitionServiceEnd)
        recognition.current?.addEventListener('error', onSpeechRecognitionServiceError)

        if (props.editing) {
            const {
                czechLexeme: { text: czText },
                englishLexeme: { text: enText },
                langToFocus,
            } = props.editing
            setInputText(langToFocus !== Language.ENGLISH ? czText : enText)
            setTranslationText(
                langToFocus === Language.ENGLISH ? czText : enText
            )
            setInputLanguage(langToFocus)
            setCurrentView(CurrentView.TRANSLATING)
        } else {
            context.setCurrentlyEditingLexemePair(undefined)
        }

        return () => {
            recognition.current?.removeEventListener('result', onSpeechRecognitionServiceResult)
            recognition.current?.removeEventListener('start', onSpeechRecognitionServiceStart)
            recognition.current?.removeEventListener('end', onSpeechRecognitionServiceEnd)
            recognition.current?.removeEventListener('error', onSpeechRecognitionServiceError)
        }
    }, [])

    useEffect(() => {
        ;(async () => {
            if (currentView === CurrentView.CONFIRMATION) {
                setTimeout(complete, ADD_CONFIRMATION_TIMEOUT_MS)
            }
            if (currentView === CurrentView.TRANSLATING && !props.editing) {
                setIsTranslationLoading(true)
                await translate()
                setIsTranslationLoading(false)
            }
        })()
    }, [currentView])

    const complete = () => {
        setInputText('')
        setTranslationText('')
        if (props.editing) {
            context.setActiveOverlay(ActiveOverlay.RECENT)
        } else {
            setCurrentView(CurrentView.ENTRY_POINT)
        }
    }

    const translate = async () => {
        try {
            const translation = await Api.postTranslation(
                inputText,
                inputLanguage,
                inputLanguage === Language.CZECH
                    ? Language.ENGLISH
                    : Language.CZECH
            )
            setTranslationText(translation)
            setCurrentView(CurrentView.TRANSLATING)
        } catch {
            context.setAppError(AppError.REQUEST_TRANSLATION_FAILED)
        }
    }

    const toggleInputLang = () => {
        const newLang =
            inputLanguage === Language.ENGLISH
                ? Language.CZECH
                : Language.ENGLISH
        setInputLanguage(newLang)
        if (recognition.current)
            recognition.current.lang =
                newLang === Language.CZECH ? 'cs-CZ' : 'en-US'
    }

    const onRecordOverlayClose = () => {
        recognition.current?.stop()
        setInputText('')
        setIsRecording(false)
        setCurrentView(CurrentView.ENTRY_POINT)
    }

    const onRecordButtonClicked = () => {
        if (!isRecording) {
            recognition.current?.start()
            setCurrentView(CurrentView.RECORDING)
        } else {
            recognition.current?.stop()
            if (inputTextRef.current === '') {
                setCurrentView(CurrentView.ENTRY_POINT)
            } else {
                setCurrentView(CurrentView.TRANSLATING)
            }
        }
    }

    const onDeleteButtonClicked = async () => {
        if (props.editing) {
            if (confirm(dictionary.CONFIRM_DELETE_LEXEME_PAIR)) {
                const czId = props.editing.czechLexeme.id
                const enId = props.editing.englishLexeme.id
                if (
                    !props.editing.czechLexeme.id ||
                    !props.editing.englishLexeme.id
                ) {
                    Logger.error(
                        '[AddLexeme.onDeleteButtonClicked]: invalid lexeme pair'
                    )
                    return
                }
                try {
                    await Api.deleteLexemePair(czId!, enId!)
                } catch {
                    context.setAppError(
                        AppError.REQUEST_DELETE_LEXEME_PAIR_FAILED
                    )
                }
            }
            context.setActiveOverlay(ActiveOverlay.RECENT)
        }
    }

    const onSaveOrUpdateButtonClicked = async () => {
        if (context.loggedInUser === undefined) return

        const enLexeme = new Lexeme(context.loggedInUser.googleSub)
        enLexeme.text =
            inputLanguage === Language.ENGLISH ? inputText : translationText
        enLexeme.id = props.editing ? props.editing.englishLexeme.id : null

        const czLexeme = new Lexeme(context.loggedInUser.googleSub)
        czLexeme.text =
            inputLanguage === Language.CZECH ? inputText : translationText
        czLexeme.id = props.editing ? props.editing.czechLexeme.id : null

        try {
            await Api.postLexemePair({
                enLexeme,
                czLexeme,
                pairingNotes: '',
                clientDate: getMysqlDateIgnoreTZ(new Date())!
            })
            setInputText('')
            setTranslationText('')
            setCurrentView(CurrentView.CONFIRMATION)
        } catch {
            context.setAppError(
                AppError.REQUEST_INSERT_OR_UPDATE_LEXEME_PAIR_FAILED
            )
        }
    }

    const onInputTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value)
    }

    const onClearInputTextButtonClicked = () => {
        // TODO - make sure that translation text is also cleared
        setInputText('')
        setTranslationText('')
    }

    const onClearTranslationTextButtonClicked = () => {
        setTranslationText('')
    }

    const onTranslationTextChanged = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        setTranslationText(event.target.value)
    }

    const onInputAreaSelectedForTyping = () => {
        setCurrentView(CurrentView.TYPING)
    }

    const onCloseTypingView = () => {
        setInputText('')
        setTranslationText('')
        setCurrentView(CurrentView.ENTRY_POINT)
    }

    return (
        <>
            <h1>
                {props.editing
                    ? dictionary.PAGETITLE_EDIT
                    : dictionary.PAGETITLE_ADD}
            </h1>
            <>
                {currentView === CurrentView.ENTRY_POINT ? (
                    <>
                        <InputLanguageButton
                            language={inputLanguage}
                            onClick={toggleInputLang}
                        />
                        <CenteredContent>
                            <InputArea
                                autoFocus={false}
                                onClick={onInputAreaSelectedForTyping}
                                onChange={() => {}}
                                onClear={onClearInputTextButtonClicked}
                                placeholder={
                                    inputLanguage === Language.ENGLISH
                                        ? 'Type here'
                                        : 'Napište zde'
                                }
                                value={inputText}
                            />
                            <SpeechButton
                                onClick={onRecordButtonClicked}
                                className={CUSTOM_BUTTON_CLASS}
                            >
                                {CUSTOM_BUTTON_LABEL.mic}
                            </SpeechButton>
                        </CenteredContent>
                    </>
                ) : null}

                {currentView === CurrentView.TYPING ? (
                    <>
                        <InputLanguageButton
                            language={inputLanguage}
                            onClick={toggleInputLang}
                        />
                        <CenteredContent>
                            <InputArea
                                autoFocus={true}
                                onClick={onInputAreaSelectedForTyping}
                                placeholder={
                                    inputLanguage === Language.ENGLISH
                                        ? 'Type here'
                                        : 'Napište zde'
                                }
                                value={inputText}
                                onChange={onInputTextChanged}
                                onClear={onClearInputTextButtonClicked}
                            />
                            <div className="buttonRow">
                                <SpeechButton
                                    onClick={onRecordButtonClicked}
                                    className={CUSTOM_BUTTON_CLASS}
                                    $isSmallVersion={true}
                                >
                                    {CUSTOM_BUTTON_LABEL.mic}
                                </SpeechButton>

                                {inputText !== '' && (
                                    <TranslateButton onClick={translate}>
                                        {dictionary.BUTTON_TRANSLATE}
                                    </TranslateButton>
                                )}
                            </div>
                        </CenteredContent>
                    </>
                ) : null}

                {currentView === CurrentView.RECORDING ? (
                    <CenteredContent>
                        <RecognisedText>
                            {inputText.length > 50
                                ? inputText.substring(0, 100) + '...'
                                : inputText}
                        </RecognisedText>
                        <SpeechButton
                            onClick={onRecordButtonClicked}
                            className={CUSTOM_BUTTON_CLASS}
                            $isRecording={true}
                        >
                            {CUSTOM_BUTTON_LABEL.mic}
                        </SpeechButton>
                        <RecordingMessage>
                            {dictionary.RECORDING_INSTRUCTIONS}
                        </RecordingMessage>
                        <CustomButton
                            onClick={onRecordOverlayClose}
                            size="10vw"
                            label={CUSTOM_BUTTON_LABEL.deleteForever}
                        />
                    </CenteredContent>
                ) : null}

                {currentView === CurrentView.TRANSLATING ? (
                    <>
                        <CenteredContent>
                            <InputWithLanguageFlag
                                inputText={inputText}
                                inputLanguage={inputLanguage}
                                onInputTextChanged={onInputTextChanged}
                                onClearInputTextButtonClicked={
                                    onClearInputTextButtonClicked
                                }
                            />

                            <hr />

                            <InputWithLanguageFlag
                                inputLanguage={
                                    inputLanguage === Language.ENGLISH
                                        ? Language.CZECH
                                        : Language.ENGLISH
                                }
                                inputText={translationText}
                                onInputTextChanged={onTranslationTextChanged}
                                onClearInputTextButtonClicked={
                                    onClearTranslationTextButtonClicked
                                }
                                placeholder={
                                    isTranslationLoading
                                        ? dictionary.PLACEHOLDER_TRANSLATION_LOADING
                                        : dictionary.PLACEHOLDER_TRANSLATION
                                }
                            />

                            <div className="buttonRow">
                                <div>
                                    {!props.editing && <CustomButton
                                        onClick={complete}
                                        size="10vw"
                                        label={CUSTOM_BUTTON_LABEL.deleteForever}
                                    />}
                                    {props.editing && (
                                        <CustomButton
                                            label={CUSTOM_BUTTON_LABEL.deleteForever}
                                            size="10vw"
                                            onClick={onDeleteButtonClicked}
                                        />
                                    )}
                                </div>
                                {inputText !== '' && translationText === '' && (
                                    <TranslateButton onClick={translate}>
                                        {dictionary.BUTTON_TRANSLATE}
                                    </TranslateButton>
                                )}
                                {inputText !== '' && translationText !== '' && (
                                    <CustomButton
                                        onClick={onSaveOrUpdateButtonClicked}
                                        size="15vw"
                                        label={CUSTOM_BUTTON_LABEL.save}
                                    />
                                )}
                            </div>
                        </CenteredContent>
                    </>
                ) : null}

                {currentView === CurrentView.CONFIRMATION ? (
                    <>
                        <CenteredContent>
                            <InstructionText>
                                {props.editing
                                    ? dictionary.CONFIRM_WORD_UPDATED
                                    : dictionary.CONFIRM_WORD_ADDED}
                            </InstructionText>
                            <button onClick={() => context.setActiveOverlay(ActiveOverlay.RECENT)}>{dictionary.BUTTON_RECENT}</button>
                        </CenteredContent>
                    </>
                ) : null}
            </>
        </>
    )
}
